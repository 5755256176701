<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-form @submit="onSubmit" @reset="onReset">
        <!-- 型号名称 -->
        <b-form-group
          label="Device Code Name:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="modelName"
          label-class="requiredRow"
        >
          <b-form-input
            id="modelName"
            v-model="form.modelName"
            placeholder="Please enter"
            trim
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Device Name:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="deviceName"
          label-class="requiredRow"
        >
          <b-form-input
            id="deviceName"
            v-model="form.deviceName"
            placeholder="Please enter"
            trim
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');"
          ></b-form-input>
        </b-form-group>

        <!-- 品牌标识 -->
        <b-form-group
          label="Brand Identity:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="brand-identity"
          label-class="requiredRow"
        >
          <div class="d-flex flex-wrap">
            <b-form-checkbox
              v-for="(label, index) in types.selectAllBrandIdentity"
              :required="true"
              :key="index"
              v-model="form.brandIdentityId"
              :value="label.id"
              class="mr-2 mt-2"
            >
              {{ label.brandName }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group
          label="Curve Type:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="curve-type"
          label-class="requiredRow"
        >
          <div class="d-flex flex-wrap">
            <b-form-checkbox
              v-for="(label, index) in types.curveType"
              :required="true"
              :key="index"
              v-model="form.curveType"
              :value="label.id"
              class="mr-2 mt-2"
            >
              {{ label.typeName }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group
          label="Write Type:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="write-type"
          label-class="requiredRow"
        >
          <div class="d-flex flex-wrap">
            <b-form-checkbox
              v-for="(label, index) in types.writeType"
              :required="true"
              :key="index"
              v-model="form.writeType"
              :value="label.id"
              class="mr-2 mt-2"
            >
              {{ label.typeName }}
            </b-form-checkbox>
          </div>
        </b-form-group>
        <b-form-group
          label="Applicable Device:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="applicable-device"
          label-class="requiredRow"
        >
          <div class="d-flex flex-wrap">
            <b-form-checkbox
              v-for="(label, index) in types.selectAllDeviceModel"
              :required="true"
              :key="index"
              v-model="form.deviceModelId"
              :value="label.id"
              class="mr-2 mt-2"
            >
              {{ label.modelName }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group
          label="Cartridge Type:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="cartridge-type"
          label-class="requiredRow"
        >
          <div class="d-flex flex-wrap">
            <b-form-checkbox
              v-for="(label, index) in types.cartridgeType"
              :required="true"
              :key="index"
              v-model="form.type"
              :value="label.id"
              class="mr-2 mt-2"
            >
              {{ label.name }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <!-- 发热丝材质 -->
        <!-- <b-form-group
					label="Heating Wire Material:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="heatingWireMaterial"
					label-class="requiredRow"
				>
					<b-form-select
						id="customer"
						v-model="form.atomizingWireId"
						:options="types.selectAllAtomizingWire"
						value-field="id"
						text-field="name"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
					>
					</b-form-select>
				</b-form-group> -->

        <!-- 发热丝尺寸 -->
        <!-- <b-form-group
					label="Heating Wire Size:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="heatingWireSize"
					label-class="requiredRow"
				>
					<b-form-input
						id="heatingWireSize"
						v-model="form.heatingWireSize"
						placeholder="Please enter"
						trim
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');"
					></b-form-input>
				</b-form-group> -->

        <!-- 发热丝孔隙率 -->
        <!-- <b-form-group
					label="Heating Wire Porosity:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="heatingWirePorosity"
					label-class="requiredRow"
				>
					<b-form-input
						id="heatingWirePorosity"
						v-model="form.heatingWirePorosity"
						placeholder="Please enter"
						trim
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');"
					></b-form-input>
				</b-form-group> -->

        <!-- 进油孔 -->
        <!-- <b-form-group
					label="Oil Hole:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="oilInlet"
					label-class="requiredRow"
				>
					<b-form-input
						id="oilInlet"
						v-model="form.oilInlet"
						placeholder="Please enter"
						trim
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');"
					></b-form-input>
				</b-form-group> -->

        <!-- 阻值 -->
        <!-- <b-form-group
          label="Resistance:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="resistance"
        >
          <b-form-input
            id="resistance"
            v-model="form.resistance"
            placeholder="Please enter"
            trim
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');"
          ></b-form-input>
        </b-form-group> -->

        <!-- 空烟弹阻值范围 -->
        <!-- <b-form-group
					label="Resistance range of empty cartridges:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="modelName"
					label-class="requiredRow"
					v-if="form.type != '' && form.type != 3 && form.type != 7"
				>
					<b-row>
						<b-col>
							<b-form-input
								id="emptyCartsMinResistance"
								v-model="form.emptyCartsMinResistance"
								placeholder="Please enter"
								trim
								number
								required
								oninvalid="setCustomValidity('Please enter');"
								onchange="setCustomValidity('');if(value!='' && this.form.emptyCartsMaxResistance.value!='')if(parseFloat(value) >= parseFloat(this.form.emptyCartsMaxResistance.value)) value=0;"
							>
							</b-form-input>
						</b-col>
						~
						<b-col>
							<b-form-input
								id="emptyCartsMaxResistance"
								v-model="form.emptyCartsMaxResistance"
								placeholder="Please enter"
								trim
								number
								required
								oninvalid="setCustomValidity('Please enter');"
								onchange="setCustomValidity('');if(value!='' && this.form.emptyCartsMinResistance.value!='')if(parseFloat(value) =< parseFloat(this.form.emptyCartsMinResistance.value)) value=0;"
							>
							</b-form-input>
						</b-col>
					</b-row>
				</b-form-group> -->

        <!-- 灌装阻值范围 -->
        <!-- <b-form-group
					label="Resistance range of fill cartridges:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="modelName"
					label-class="requiredRow"
					v-if="(form.type == 1 || form.type == 5) && form.type != 3"
				>
					<b-row>
						<b-col>
							<b-form-input
								id="oilCartsMinResistance"
								v-model="form.oilCartsMinResistance"
								placeholder="Please enter"
								trim
								number
								required
								oninvalid="setCustomValidity('Please enter');"
								onchange="setCustomValidity('');if(value!='' && this.form.oilCartsMaxResistance.value!='')if(parseFloat(value) >= parseFloat(this.form.oilCartsMaxResistance.value)) value=0;"
							>
							</b-form-input>
						</b-col>
						~
						<b-col>
							<b-form-input
								id="oilCartsMaxResistance"
								v-model="form.oilCartsMaxResistance"
								placeholder="Please enter"
								trim
								number
								required
								oninvalid="setCustomValidity('Please enter');"
								onchange="setCustomValidity('');if(value!='' && this.form.oilCartsMinResistance.value!='')if(parseFloat(value) <= parseFloat(this.form.oilCartsMinResistance.value)) value=0;"
							>
							</b-form-input>
						</b-col>
					</b-row>
				</b-form-group> -->

        <!-- TCR -->
        <!-- <b-form-group
					label="TCR:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="tcr"
				>
					<b-form-input
						id="tcr"
						v-model="form.tcr"
						placeholder="Please enter"
						trim
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\d]/g,'')"
					></b-form-input>
				</b-form-group> -->

        <!-- 是否包棉 -->
        <!-- <b-form-group
					label="Cotton coil:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="cottonBag"
					label-class="requiredRow"
				>
					<b-form-radio-group
						id="radio-group-1"
						v-model="form.cottonBag"
						:options="types.cottonBags"
						name="radio-options"
						required
						style="margin-top: 5px"
					></b-form-radio-group>
				</b-form-group> -->

        <!-- 电压 -->
        <!-- <b-form-group
					label="Power:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="power"
					label-class="requiredRow"
				>
					<b-form-input
						id="power"
						v-model="form.power"
						placeholder="Please enter"
						trim
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\d]/g,'')"
					></b-form-input>
				</b-form-group> -->

        <!-- 备注 -->
        <b-form-group
          label="Notes"
          label-cols-lg="3"
          label-align-sm="right"
          label-for="remark"
        >
          <b-form-textarea
            id="remark"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-cols-lg="3">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
import goback from "@/components/goback";

/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    goback,
  },
  data() {
    return {
      page: {
        title: "Cartridge Model",
        items: [
          {
            text: "Device Manage",
            // href: "/"
          },
          {
            text: "Cartridge Model",
            // href: "/"
          },
          {
            text: this.editId
              ? "Cartridge Model Modify"
              : "Cartridge Model Create",
            active: true,
          },
        ],
      },
      form: {
        id: "",
        modelName: "",
        deviceModelId: 8,
        type: 3,
        atomizingWireId: "",
        heatingWireSize: "",
        heatingWirePorosity: "",
        oilInlet: "",
        resistance: "",
        emptyCartsMinResistance: "", //空烟弹最小阻值
        emptyCartsMaxResistance: "", //空烟弹最大阻值
        oilCartsMinResistance: "", //油弹最小阻值
        oilCartsMaxResistance: "", //油弹最大阻值
        tcr: "",
        cottonBag: "",
        power: "", //电压
        remarks: "",
        brandIdentityId: 8,
        curveType: 1,
        deviceName: "",
        writeType: 1,
      },
      types: {
        selectAllBrandIdentity: [],
        curveType: [
          { typeName: "One curve", id: 1 },
          { typeName: "Two curve", id: 2 },
        ],
        writeType: [
          { typeName: "Default", id: 1 },
          { typeName: "AP02 Write method", id: 2 },
        ],
        cottonBags: [
          { text: "Yes", value: 0 },
          { text: "No", value: 1 },
        ],
        selectAllAtomizingWire: [{ name: "Please select", id: "" }],
        selectAllDeviceModel: [],
        cartridgeType: [],
      },
      editId: window.location.search,
      editor: ClassicEditor,
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    this.init();
    if (this.editId) {
      let id = this.editId.split("=")[1];
      this.formData(id);
    }
  },
  mounted() {},
  methods: {
    onReady: onReady,
    init() {
      this.$api.Dropdown.selectAllBrandIdentity({}).then((res) => {
        if (res.success && res.data) {
          this.types.selectAllBrandIdentity =
            this.types.selectAllBrandIdentity.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
      this.$api.Dropdown.selectAllAtomizingWire().then((res) => {
        if (res.success && res.data) {
          this.types.selectAllAtomizingWire =
            this.types.selectAllAtomizingWire.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
      this.$api.Dropdown.selectAllDeviceModel().then((res) => {
        if (res.success && res.data) {
          this.types.selectAllDeviceModel =
            this.types.selectAllDeviceModel.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
      this.$api.Dropdown.selectAllCartridgeType({ status: "0" }).then((res) => {
        if (res.success && res.data) {
          this.types.cartridgeType = this.types.cartridgeType.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (!this.form.brandIdentityId) {
        this.$bvToast.toast("Please select brand identity.");
        return;
      }
      if (!this.form.curveType) {
        this.$bvToast.toast("Please select curve type.");
        return;
      }
      if (!this.form.deviceModelId) {
        this.$bvToast.toast("Please select applicable device.");
        return;
      }
      if (!this.form.type) {
        this.$bvToast.toast("Please select cartridge type.");
        return;
      }
      if (!this.form.writeType) {
        this.$bvToast.toast("Please select Write type.");
        return;
      }
      if (this.form.id) {
        this.$api.CartridgeModel.testSmokeBombModelUpdate(this.form).then(
          (res) => {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
            if (res.success) {
              this.$router.go(-1);
            }
          }
        );
      } else {
        this.$api.CartridgeModel.testSmokeBombModelAdd(this.form).then(
          (res) => {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
            if (res.success) {
              this.$router.go(-1);
            }
          }
        );
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        id: "",
        modelName: "",
        deviceModelId: "",
        atomizingWireId: "",
        heatingWireSize: "",
        heatingWirePorosity: "",
        oilInlet: "",
        resistance: "",
        cottonBag: "",
        remarks: "",
      };
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
    formData(id) {
      this.$api.CartridgeModel.selectTestSmokeBombModelId({
        id: id,
      }).then((res) => {
        if (res.success) {
          this.form = res.data;
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
  },
};
</script>
